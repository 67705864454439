.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cus-toggle {
  position: absolute;

  transform: translate3d(-40px, 47px, 0px) !important;
  left: -45px;
}

.cus-franchise {
  position: absolute;

  transform: translate3d(-12px, 47px, 0px) !important;
  left: -45px;
}
.cus-fran {
  width: 60% !important;
}

.faq-section .mb-0 > a {
  display: block;
  position: relative;
}

.cus-h5 a {
  font-size: 19px;
  color: #000;
}
#comments {
  width: 89%;
  height: 38px;
  display: block;
  margin: auto;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
  text-align: center;
}

.bg-gradientorange {
  background: linear-gradient(85deg, #18a9f7, #b5426d);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card {
  border-radius: 0;
}
.fa-link:before {
  content: "\f0c1";
}
.form-selection {
  background-color: #e9ecef;
  opacity: 1;
}
.cus-area-text {
  height: 70px;
  width: 89%;

  display: block;
  margin: auto;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
  text-align: center;
}
.cus-table {
  /* position: relative;
  bottom: 37px; */
  padding: 0px 20px !important;
  padding-bottom: 10px !important;
}
/* .ePEgUr {
  z-index: inherit !important;
} */
.cus-emi-data {
  background: #148fd1;
}
.cus-emi-title {
  color: #fff;
  display: block;
  margin: auto;
  position: relative;
  bottom: 19px;
}
/* .cIdNNF {
  padding-left: 0px !important;
  padding-right: 0px !important;
} */
